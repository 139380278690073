const languages = require('./supportedLanguages')

const supportedLanguages = languages

const languageCodeNameMap = supportedLanguages.reduce((obj, val) => { obj[val.languageCode] = val.name; return obj }, {})
const languageCodeLocalizedNameMap = supportedLanguages.reduce((obj, val) => { obj[val.languageCode] = val.localizedName; return obj }, {})

const mapLanguageCodeToName = code => languageCodeNameMap[code] || ''
const mapLanguageCodeToLocalizedName = code => languageCodeLocalizedNameMap[code] || ''
/**
 * {Object.<string: string>} country icon flag icon type enums
 */
const flagCountryIconType = {
    rounded: 'rounded',
    circle: 'circle',
    square: 'square'
}

/**
 * {Object.<symbolStyle:string>} language status enum
 */
const languageStatus = {
    INACTIVE: 'Inactive',
    ACTIVE: 'Active',
    DELETED: 'Deleted'
}

module.exports = {
    languageStatus,
    flagCountryIconType,
    supportedLanguages,
    languageCodeNameMap,
    mapLanguageCodeToName,
    mapLanguageCodeToLocalizedName
}
